<template>
  <div class="widgetContainer bg-white widgetContainer--scrollable pay">
    <div class="widgetContainer__header-fixed" v-if="paymentStatus == ''">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <span class="icon-Arrow-big-left cancel" @click="cancel" />
          <p class="title">
            {{ $t('accountType_intrabank') }}
          </p>
          <span class="el-icon-close" @click="drawerClose" />
        </div>
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="pay__details">
        <el-form class="wise-form">
          <el-form-item class="is-no-asterisk" prop="name" :label="$t('Name')">
            <el-input :value="getSelectedContactData.name" />
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="accountNumber"
            :label="$t('contact_Account_AccNo')">
            <AccountNumber v-model="contactDetails.intrabank.accountNumber" />
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="amount"
            :label="$t('pay_section_amount')">
            <Amount :precision="2" v-model="formData.amount" :masked="true" />
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="description"
            :label="$t('pay_row_purpose')">
            <el-input
              placeholder="Purpose of the payment"
              v-model="formData.description" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="!isFormValid"
        @click="next()"
        class="width-100 el-button__brand brand">
        {{ $t('pay') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Amount from '@/components/Amount';
import AccountNumber from '@/components/AccountNumber';
import { payToContact } from '../utils/pay';

export default {
  components: {
    Amount,
    AccountNumber
  },
  created() {
    if (!this.getPaymentType) this.$router.push('/pay/paymentType');

    if (
      !this.getSelectedContactData ||
      !this.getSelectedContactData.intrabank ||
      this.getSelectedContactData.intrabank.accountNumber
    ) {
      this.contactChange = true;
      this.contactDetails.intrabank.accountNumber =
        this.getSelectedContactData.intrabank.accountNumber;
    }
  },
  computed: {
    ...mapGetters('pay', ['getPaymentType']),
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    isFormValid() {
      if (
        this.formData.amount !== '' &&
        this.formData.description !== '' &&
        this.contactDetails.intrabank.accountNumber &&
        this.contactDetails.intrabank.accountNumber !== '' &&
        this.contactDetails.intrabank.accountNumber.length >= 6 &&
        this.contactDetails.intrabank.accountNumber.length <= 17
      ) {
        const amount = parseFloat('' + this.formData.amount);
        return amount >= 0.05;
      }
      return false;
    }
  },
  data() {
    return {
      formData: {
        amount: '0',
        description: ''
      },
      contactChange: false,
      contactDetails: {
        intrabank: {
          accountNumber: ''
        }
      },
      paymentStatus: '',
      paymentInfo: ''
    };
  },
  methods: {
    ...mapActions('pay', ['intrabankPay']),
    ...mapActions('account', ['getAccount']),
    ...mapMutations('transaction', ['setRecentTransactions']),
    ...mapActions('contact', ['updateContact']),
    cancel() {
      this.drawerBack();
    },
    async next() {
      if (this.isFormValid) {
        const loader = this.showLoader();

        try {
          if (
            this.contactChange === false &&
            this.getSelectedContactData &&
            this.getSelectedContactData.intrabank &&
            this.getSelectedContactData.intrabank.accountNumber &&
            this.contactDetails.intrabank.accountNumber !==
              this.getSelectedContactData.intrabank.accountNumber
          ) {
            this.contactChange = true;
          }

          const data = await payToContact(
            'intrabank',
            {
              accountId: this.getSelectedAccount.id,
              contactId: this.getSelectedContactData.id,
              amount: '' + this.formData.amount,
              description: this.formData.description
            },
            this.contactChange
              ? {
                id: this.getSelectedContactData.id,
                ...this.contactDetails
              }
              : null
          );

          this.paymentInfo = data;
          this.paymentStatus = data.status;
          this.setRecentTransactions([]);

          this.drawerPush('pay-success');

          loader.close();
        } catch (e) {
          console.log(e);
          this.apiErrorMessage(e);
          loader.close();
        }
      }
    },
    done() {
      this.goToDashboard();
    }
  }
};
</script>
<style lang="scss">
.pay {
  &--completed {
    padding-top: 65px;

    .payment-done-header {
      text-align: center;
      padding-bottom: 48px;
    }

    .icon {
      font-size: 75px;
      margin-bottom: 36px;
      display: inline-block;

      .path1 {
        &:before {
          color: var(--color-green);
        }
      }
    }

    .title {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 16px;
    }

    .description {
      color: rgba(#3c3c43, 0.6);
      font-size: 14px;
      line-height: 20px;
    }

    .el-button {
      margin-top: auto;
    }
  }

  .widgetContainer__header-fixed {
    padding: 0;
  }

  .welcome__illustration {
    margin: 10% auto;
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
    margin-bottom: 10px;
  }

  .widgetContainer__body {
    position: relative;
  }

  &__header {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding: 6px 0;
  }

  &__details {
    .header-1 {
      text-transform: capitalize;
    }

    .sub-header {
      color: rgba(#3c3c43, 0.6);
      font-size: 13px;
      padding-bottom: 7px;
      text-transform: uppercase;
    }
  }

  .purpose-input {
    outline: none;
    border: none;
    text-align: right;
    input {
      text-align: right;
      font-weight: bold;
      color: #000000;
    }
  }
  .success-desc {
    font-size: 17px;
    color: #3c3c43;
    margin-bottom: 25px;
  }

  &__done-btn {
    width: 100%;
    &.is-disabled {
      opacity: 0.5;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 16px;
    border-top: 1px solid #eeeeee;
    button {
      width: 100%;
    }
  }
}
</style>
